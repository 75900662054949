$enable-rfs: false;

$white: #FFFFFF;
$blue: #007BC7;
$cyan: #3DB5E6;

$gray-900: #515A5F;
$dark: $gray-900;

$body-bg: #F8F9FA;
$body-color: $dark;

$toast-max-width: auto;
